import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import {
    ProductAvailableQuantity,
    ProductsDataActions,
    ProductType,
    ProductWarehouseInventoryAvailability,
    ReleasedProductType
} from '@msdyn365-commerce/retail-proxy';

import {
    getSelectedVariant,
    SelectedVariantInput,
    ArrayExtensions,
    getSelectedProductIdFromActionInput,
    DeliveryMode,
    IProductInventoryInformation,
    createInventoryAvailabilitySearchCriteria,
    mapAggregatedProductInventoryInformation
} from '@msdyn365-commerce-modules/retail-actions';

import { cloneDeep } from 'lodash';

/**
 * Input class for the getProductAvailabilitiesForBackorder Data Action.
 */
export class ProductAvailabilitiesForBackorderInput implements IActionInput {
    public productId: number;

    public channelId: number;

    public constructor(productId: number | string, channelId: number) {
        this.productId = typeof productId === 'string' ? +productId : productId;
        this.channelId = channelId;
    }

    /**
     * GetCacheKey.
     * @returns - Returns string.
     */
    public getCacheKey = (): string => 'AvailabilitiesBackorder';

    /**
     * GetCacheObjectType.
     * @returns - Cache Object Type string.
     */
    public getCacheObjectType = (): string => 'ProductAvailabilitiesForBackorder';

    /**
     * DataCacheType.
     * @returns - CacheType string.
     */
    public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getProductAvailabilitiesForBackorder data action.
 * @param inputData - The input data passed to the createInput method.
 * @returns - ProductAvailabilitiesForBackorder or error.
 */
export const createProductAvailabilitiesForBackorderInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): ProductAvailabilitiesForBackorderInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new ProductAvailabilitiesForBackorderInput(+productId, +inputData.requestContext.apiSettings.channelId);
    }
    throw new Error('Unable to create ProductAvailabilitiesForBackorderInput, no productId found on module config or query');
};

/**
 * The action method for the getProductAvailabilitiesForBackorder data action.
 * @param input - ProductAvailabilitiesForBackorder.
 * @param ctx - Action Context.
 * @returns - Product inventory information.
 */
export async function getProductAvailabilitiesForBackorderAction(
    input: ProductAvailabilitiesForBackorderInput,
    ctx: IActionContext
): Promise<IProductInventoryInformation[] | undefined> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId, undefined, undefined, ctx.requestContext);
    const productInventoryInformation: IProductInventoryInformation[] = [];
    const cloneCtx = cloneDeep(ctx);
    cloneCtx.requestContext.app.config.enableStockCheck = true;

    try {
        const productResult = await getSelectedVariant(selectedVariantInput, ctx);

        // Don't get availability if it is service item && enableStockCheck is disbaled.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Reading config.
        if (
            productResult &&
            productResult.ItemTypeValue !== ReleasedProductType.Service &&
            productResult.ProductTypeValue !== ProductType.Master
        ) {


            const shippingSearchCriteria = createInventoryAvailabilitySearchCriteria(
                ctx,
                [productResult.RecordId],
                true,
                undefined,
                undefined,
                DeliveryMode.shipping
            );
            const pickupSearchCriteria = createInventoryAvailabilitySearchCriteria(
                ctx,
                [input.productId],
                false,
                true,
                undefined,
                DeliveryMode.pickup
            );
            const shippingAvailability = await ProductsDataActions.getEstimatedAvailabilityAsync(
                { callerContext: ctx },
                shippingSearchCriteria
            );

            if (shippingAvailability.ProductWarehouseInventoryAvailabilities) {
                const shippingInventory = mapAggregatedProductInventoryInformation(cloneCtx, shippingAvailability);
                if (ArrayExtensions.hasElements(shippingInventory)) {
                    shippingInventory[0].deliveryType = DeliveryMode.shipping;
                    productInventoryInformation.push(shippingInventory[0]);
                }
            }
            const pickupAvailability = await ProductsDataActions.getEstimatedAvailabilityAsync(
                { callerContext: ctx },
                pickupSearchCriteria
            );


            if (pickupAvailability.ProductWarehouseInventoryAvailabilities) {
                const pickupInventory = mapAggregatedProductInventoryInformation(cloneCtx, pickupAvailability);
                if (ArrayExtensions.hasElements(pickupInventory)) {
                    pickupInventory[0].deliveryType = DeliveryMode.pickup;
                    productInventoryInformation.push(pickupInventory[0]);
                }
            }
            return productInventoryInformation;
        }
        return undefined;
    } catch (error) {
        ctx.telemetry.exception(error as Error);
        ctx.telemetry.debug('[getPriceForSelectedVariantAction]Error executing action');
        return undefined;
    }
}

/**
 * The function that maps a ProductWareHouse object into a ProductAvailabilityQuantity.
 * @param productsWarehouseInventory - Product warehouse inventory.
 * @returns Product Available quantity.
 */
export function mergeProductWarehouseToProductAvailabities(
    productsWarehouseInventory: ProductWarehouseInventoryAvailability[]
): ProductAvailableQuantity[] {
    const productAvailable: ProductAvailableQuantity[] = [];
    if (ArrayExtensions.hasElements(productsWarehouseInventory)) {
        for (const product of productsWarehouseInventory) {
            if (product.TotalAvailable !== undefined && product.ProductId !== undefined) {
                productAvailable.push({ ProductId: product.ProductId, AvailableQuantity: product.TotalAvailable });
            }
        }
    }
    return productAvailable;
}

/**
 * The complete getProductAvailabilitiesForBackorder data action
 * Get the currently selected variant via the getSelectedVariant data action, and
 * then gets the availabilities for the variant via the getProductAvailabilities RetailServer API.
 */
export const getProductAvailabilitiesForBackorderActionDataAction = createObservableDataAction({
    id: 'get-product-avail-for-backorders',
    action: <IAction<IProductInventoryInformation[] | null>>getProductAvailabilitiesForBackorderAction,
    input: createProductAvailabilitiesForBackorderInput
});

export default getProductAvailabilitiesForBackorderActionDataAction;
